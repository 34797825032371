.loadingTable {
  width: 100%;
  height: 200px;
  position: relative;

  .lds-ellipsis {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 50px;
    height: 50px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #ff5722;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
}
.modal-choose-alias-accounts {
  .modal-body {
    button {
      color: #000;
      width: 100%;
      margin-bottom: 5px;
      padding: 12px;
      box-shadow: 1px 1px 1px 1px #eee !important;
      transition: all 0.2s ease !important;
      text-align: left;
      &:hover,
      &:focus {
        border: 1px solid #ccc !important;
        box-shadow: 1px 1px 1px 1px #ccc !important;
        transition: all 0.2s ease !important;
      }
    }
    .modal-description{
      margin-bottom: 30px;
      text-align: center;
      h3{
        margin: 0;
      }
      p{
        font-size: 16px;
        span{
          color: rgb(17, 102, 187);
          font-weight: bold;
        }
      }
    }
  }
  .modal-dialog {
    box-shadow: none !important;
    max-width: 550px!important;
  }
  h5.modal-title{
    font-size: 16px!important;
    img{
      max-width: 60px;
    }
  }
  .modal-content{
    border: 1px solid #ccc;
  }
  .alias-avatar-item{
    display: flex;
    span{
      position: relative;
      top: -10px;
      font-size: 16px;
    }
    .avatar-content{
      width: 40px;
      height: 40px;
    }
    .main-avatar-content{
      width: 50px;
      height: 50px;
      margin-right: 15px;
      border-radius: 50%;
    }
  }
}

.m-t-12 {
  margin-top: 12px !important;
}

.login-page{
  .login-container{
    display: flex;
    flex-direction: column;
    .content{
      flex: 1;
    }
    .copyright-xs{
      position: relative;
    }
    .environment-name-login{
      color: #D0102C;
      font-weight: bold;
      font-size: 25px;
      padding-top: 5px;
    }
  }
}
@media(min-width: 768px){
  .copyright-xs{
      display: none !important;
  }
}
@media(max-width: 768px){
  .copyright-xs{
    margin-bottom: 49px;
    display: block;
  }
}
.copyright-xs{
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  text-align: center;
  background: #fff;
  width: 100%;
}
