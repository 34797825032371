.ReactTable .-padRow .rt-td {
  border: none;
}


@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.tooltip-inner {
  max-width: 350px;
  max-height: 15vh;
  overflow: auto;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }

  .border-bottom-none {
    border-bottom: none !important;
  }
  .border-top-bold {
    border-top: solid 1px rgba(0, 0, 0, 0.5) !important;
  }
  .border-right-bold {
    border-right: solid 1px rgba(0, 0, 0, 0.5) !important;
  }
  .border-bottom-bold {
    border-bottom: solid 1px rgba(0, 0, 0, 0.5) !important;
  }
  .border-left-bold {
    border-left: solid 1px rgba(0, 0, 0, 0.5) !important;
  }
  .text-font-customize {
    font-weight: bold;
  }}
